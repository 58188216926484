<template>
  <div class="server-down" :dir="localDisplayLang === 'ara' ? 'rtl' : 'ltr'">
    {{ message }}
  </div>
</template>

<script>
import { eventBus } from "@/eventBus";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      message: null,
      localDisplayLang: null,
    };
  },
  computed: {
    ...mapGetters(["appConfig"]),
  },
  mounted() {
    this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");

    eventBus.$emit("hideHeaderOptions");

    if (this.appConfig && this.appConfig.serverDownMessage) {
      if (this.localDisplayLang == "eng") {
        this.message = this.appConfig.serverDownMessage.en;
      } else if (this.localDisplayLang == "ara") {
        this.message = this.appConfig.serverDownMessage.ar;
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.server-down {
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;

  font-family: "Open Sans", sans-serif;
  letter-spacing: 0px;
  font-size: 1.5rem;
  color: #ffffff;
}

@media only screen and (max-width: 480px) {
  .server-down {
    overflow: visible;
    margin: 0 3rem;
    font-size: 1rem;
    line-height: 22px;
  }
}
</style>
